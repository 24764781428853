import request from "superagent";
import { readFromBinaryFile } from "exif-js";
import config from "../config";

export function saveMedia({
  image: { file, blob },
  owner_uuid,
  theme_uuid,
  isPublic = false,
  type = "digi_theme_temp",
  baseUrl = config.adaptiveUrl
}) {
  return new Promise((resolve, reject) => {
    request
      .post(baseUrl + "WebServices/generic/Media.asmx/Create")

      .send({
        extraParams: [],
        data: {
          config: {
            configs: [{ key: "isPublic", value: isPublic ? "true" : "false" }]
          },
          mime: blob ? "image/jpeg" : file.type,
          name: file.name,
          direction: 0,
          owner_uuid,
          parent_object_uuid: theme_uuid,
          parent_object_type: type
        }
      })
      .then(res => res.body)
      .then(res => {
        if (!res.d.success) {
          console.error("Could not create media", res.d);
          reject("Could not create media");
          return;
        }

        const uuid = res.d.data.filter(d => d.key === "uuid")[0].value;

        request
          .post(
            baseUrl +
              "WebServices/generic/Media.asmx/Upload?uuid=" +
              uuid
          )
          .attach("media", blob || file)
          .then(res => JSON.parse(res.text))
          .then(res => {
            if (!res.success) {
              reject("Could not upload media");
              return;
            }

            resolve(uuid);
          })
          .catch(err => {
            console.error(err);
            reject(err);
          });
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function updateMedia(file, baseUrl = config.adaptiveUrl) {
  return new Promise((resolve, reject) => {
    request
      .post(baseUrl + "WebServices/generic/Media.asmx/Update")
      .send({
        extraParams: [],
        data: { ...file, parent_object_type: "digi_theme_temp" }
      })
      .then(res => res.body)
      .then(res => {
        if (!res.d.success) {
          console.error("Could not update media", res.d);
          reject("Could not update media");
          return;
        }
      })
      .catch(err => {
        reject(err);
      });
  });
}

function toCoord(coord) {
  return coord[0] + coord[1] / 60 + coord[2] / 3600;
}

function getGeom(exif) {
  if (exif.GPSLongitude && exif.GPSLatitude) {
    return (
      "POINT(" +
      toCoord(exif.GPSLongitude) +
      " " +
      toCoord(exif.GPSLatitude) +
      ")"
    );
  }
  return "";
}

export function getRotatedResizedImage(
  dataUrl,
  orientation,
  callback // (blob, dataUrl) => void
) {
  const img = new Image();

  img.onload = function () {
    let height = img.height;
    let width = img.width;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (height > width) {
      if (height > 2000) {
        width = Math.floor((width / height) * 2000);
        height = 2000;
      }
    } else {
      if (width > 2000) {
        height = Math.floor((height / width) * 2000);
        width = 2000;
      }
    }

    if (4 < orientation && orientation < 9) {
      canvas.width = height;
      canvas.height = width;
    } else {
      canvas.width = width;
      canvas.height = height;
    }

    switch (orientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, width, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, width, height);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, height);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, height, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, height, width);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, width);
        break;
      default:
        break;
    }

    ctx.drawImage(img, 0, 0, width, height);

    canvas.toBlob(
      blob => {
        callback(blob, canvas.toDataURL("image/jpeg", 90));
      },
      "image/jpeg",
      90
    );
  };

  img.src = dataUrl;
}

/**
 * Process a (image) file object.
 *
 * The image blob and data URL with be rotated based on EXIF information (if available) and
 * resized to max 2000x2000 pixels.
 *
 * @param {File} file The provided file object
 * @param {(blob: Blob, dataUrl: string, geom: string, exif: any) => void} callback Callback
 *      with image blob, image as data URL, geom (if in EXIF, WKT point in EPSG:4326), and
 *      exif object
 */
export function processFile(file, callback) {
  const fileReader = new FileReader();
  fileReader.onload = e => {
    const exif = readFromBinaryFile(e.target.result);
    const geom = exif && getGeom(exif);

    const orientation = exif.Orientation || 1;

    fileReader.onload = e => {
      const dataUrl = e.target.result;

      getRotatedResizedImage(dataUrl, orientation, (blob, dataUrl) => {
        callback(blob, dataUrl, geom, exif);
      });
    };
    fileReader.readAsDataURL(file);
  };
  fileReader.readAsArrayBuffer(file);
}

/**
 * Process a (image) file object. Wraps `processFile` into Promise
 *
 * The image blob and data URL with be rotated based on EXIF information (if available) and
 * resized to max 2000x2000 pixels.
 *
 * @param {File} file The provided file object
 * @param {(blob: Blob, dataUrl: string, geom: string, exif: any) => void} callback Callback
 *      with image blob, image as data URL, geom (if in EXIF, WKT point in EPSG:4326), and
 *      exif object
 */
export function processFilePromise(file) {
  return new Promise((resolve, reject) => {
    try {
      processFile(file, (blob, dataUrl, geom, exif) =>
        resolve({
          file,
          blob,
          dataUrl,
          geom,
          exif
        })
      );
    } catch (er) {
      reject(er);
    }
  });
}
