import React, { useState, useCallback, useEffect, useMemo } from "react";
import { translate } from "react-translate";
import Icon from "../../component/Icon";
import { processFilePromise, saveMedia, updateMedia } from "../../utils/media";
import LoadingDots from "../../component/Loading/Loading";
import "./Attachments.scss";
import { TextAreaField, Field } from "react-adaptive-dt-form";
import config from "../../config";

import "react-adaptive-dt-form/lib/style.scss";

let nextId = 0;

function updateFile(files, uuid, delta) {
  const idx = files.findIndex(f => f.uuid === uuid);
  return [
    ...files.slice(0, idx),
    { ...files[idx], ...delta },
    ...files.slice(idx + 1)
  ];
}

let saveTimeoutId;

function useAttachments({
  themeUuid,
  parentUuid,
  sessionId,
  onAttachmentAdded,
  onAttachmentRemoved,
  baseUrl = config.adaptiveUrl
}) {
  const [files, setFiles] = useState([]);

  const updateImages = useCallback(
    newFiles => {
      setFiles(
        files.map(file => {
          const found = newFiles.find(i => i.file === file.file);
          return {
            ...file,
            blob: found ? found.blob : file.blob,
            src: found ? found.dataUrl : file.src
          };
        })
      );
    },
    [files, setFiles]
  );

  useEffect(() => {
    const fileList =
      files &&
      files.filter(
        img =>
          img.uuid.length > 10 &&
          !img.id &&
          img.file &&
          img.file !== null &&
          img.file.type &&
          img.file.type.indexOf("image/") > -1 &&
          !img.src &&
          !img.loading &&
          !img.error
      );

    if (fileList && fileList.length) {
      Promise.all(fileList.map(file => processFilePromise(file.file)))
        .then(updateImages)
        .catch(err => console.error(err));
    }
  }, [files, updateImages]);

  const add = useCallback(
    file => {
      const id = ++nextId;
      setFiles(files => [
        ...files.filter(f => !f.error),
        { uuid: id, name: file.name, loading: false, file }
      ]);

      saveMedia({
        sessionId,
        image: { file },
        theme_uuid: themeUuid,
        owner_uuid: parentUuid,
        isPublic: false,
        type: "digi_theme_temp",
        baseUrl
      })
        .then(uuid => {
          setFiles(files => updateFile(files, id, { uuid, loading: false }));
          onAttachmentAdded(uuid);
        })
        .catch(err => {
          console.error(err);
          setFiles(files =>
            updateFile(files, id, { loading: false, error: true })
          );
        });
    },
    [sessionId, themeUuid, parentUuid, onAttachmentAdded, baseUrl]
  );

  const remove = useCallback(
    uuid => {
      setFiles(files => files.filter(f => f.uuid !== uuid));
      onAttachmentRemoved(uuid);
    },
    [onAttachmentRemoved]
  );

  const updateComment = useCallback((file, value) => {
    setFiles(files =>
      files.map(f => {
        if (f.uuid === file.uuid) {
          f.comment = value;
        }
        return f;
      })
    );

    if (saveTimeoutId) clearTimeout(saveTimeoutId);
    saveTimeoutId = setTimeout(() => {
      updateMedia(file);
    }, 500);
  }, []);

  const saving = useMemo(() => files.some(m => m.loading), [files]);

  return { add, remove, saving, files, updateComment };
}

const Attachments = ({
  themeUuid,
  onAttachmentAdded,
  onAttachmentRemoved,
  displayForm,
  t,
  label,
  baseUrl
}) => {
  const [showDrag, setShowDrag] = useState(false);
  const { add, remove, files, updateComment } = useAttachments({
    themeUuid,
    onAttachmentAdded,
    onAttachmentRemoved,
    baseUrl
  });

  const onDragEnterOrOver = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    setShowDrag(true);
  }, []);

  const validateAndAddFiles = useCallback(
    fileList => {
      for (var i = 0; i < fileList.length; ++i) {
        add(fileList[i]);
      }
    },
    [add]
  );

  const onFileDrop = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      setShowDrag(false);
      validateAndAddFiles(e.dataTransfer.files);
    },
    [validateAndAddFiles]
  );

  return (
    <div className="attachments">
      <label className="bp3-label">{label}:</label>
      {files.map(file => (
        <div className="attachments__file" key={file.uuid}>
          <span className="filename">{file.name}</span>
          <button
            type="button"
            className="btn accent"
            onClick={() => remove(file.uuid)}
          >
            <Icon name="trash" />
          </button>
          <div key={file.uuid} className="attachments__file__details">
            <Icon name="attachment" />
            {file.error && <span className="error">{t("uploadError")}</span>}
            {!file.loading && !file.error && (
              <>
                <div className="thumbnail">
                  {file.src && <img src={file.src} alt="" />}
                  {file.uuid.length < 10 && <LoadingDots />}
                  {!file.src && !file.loading && <Icon name="attachment" />}
                </div>
                <Field label={t("comment")}>
                  <TextAreaField
                    value={file.comment}
                    onValueChange={value => {
                      updateComment(file, value);
                    }}
                  />
                </Field>
              </>
            )}
          </div>
        </div>
      ))}
      {!displayForm && (
        <div
          className="attachments__drop-area"
          onDragLeave={e => setShowDrag(false)}
          onDragEnter={onDragEnterOrOver}
          onDragOver={onDragEnterOrOver}
          onDrop={onFileDrop}
        >
          {showDrag && (
            <div className="attachments__hint">
              <Icon name="plus" />
            </div>
          )}

          <div className="attachments__input">
            <label className="bp3-label">
              <Icon name="attachment" />
              <input
                multiple
                type="file"
                name="attachments"
                onChange={e => {
                  validateAndAddFiles(e.target.files);
                  e.target.value = "";
                }}
              />
              <span>{t("select")}</span>
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default translate("Attachments")(Attachments);
