import React, { useState, useCallback } from "react";
import request from "superagent";
import RadioButtongGroup from "../../component/RadioButtonGroup";
import { Checkbox } from "@blueprintjs/core";
import Loading from "../../component/Loading/Loading";
import Config from "../../config/index";
import {
  DigiThemeManager,
  DigiThemeLoader
} from "react-adaptive-dt-form";
import { withRouter } from "react-router-dom";
import { translate } from "react-translate";
import Error from "./Error";
import Success from "./Success";
import { isInvalid, toRecord } from "../../utils/record";
import Attachments from "./Attachments";
import "./Skjema.scss";
import "react-adaptive-dt-form/lib/style.scss";

const KompostSkjema = ({ history, t }) => {
  const [saving, setSaving] = useState();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [formState, setFormState] = useState({});
  const [id, setId] = useState();
  const isRecordInvalid = isInvalid([formState]);
  const [attachments, setAttachments] = useState([]);
  const [confirmCorrectInformation, setConfirmCorrectInformation] =
    useState(false);

  const handleField = useCallback(
    props => {
      const { name } = props;
      console.log("handleField", name, props);
      switch (name) {

        case "is_borettslag":
          return <RadioButtongGroup {...props} />;

        case "keep_bio":
          return <RadioButtongGroup {...props} />;

        case "id":
        case "behandlet":
        case "date_created":
          return false;

        default:
          return;
      }
    },
    []
  );

  const save = () => {
    setSaving(true);
    request
      .post(Config.ngirkartUrl + "WebServices/client/DataView.asmx/SaveAny")
      .send({
        request: {
          theme_uuid: Config.forms.kompostSkjema.uuid,
          data: toRecord(formState),
          mediaUuids: attachments
        }
      })
      .set("Content-Type", "application/json")
      .then(res => res.body)
      .then(res => {
        if (!res.d.success) {
          console.error("Could not save kompost skjema", res.d);
          setSaving(false);
          setError(true);
          return;
        }
        setSaving(false);
        setSuccess(true);
        setId(res && res.d.data.length > 0 && res.d.data[0].value.id);
      })
      .catch(e => {
        console.error("Could not save kompost skjema", e);
        setSaving(false);
        setError(true);
      });
    setSaving(false);
  };

  const onAttachmentAdded = useCallback(
    uuid => {
      setAttachments(a => [...a, uuid]);
    },
    [setAttachments]
  );

  const onAttachmentRemoved = useCallback(
    uuid => {
      setAttachments(att => [att.filter(a => a !== uuid)]);
    },
    [setAttachments]
  );

  return (
    <>
      <h1>{t("soknadDispKompost")}</h1>
      {!success && !id && (
        <p className="skjema-header">
          {t("required")} <br />
          <span>{t("sensitive")}</span>
        </p>
      )}
      {!saving && (!success || error) && (
        <DigiThemeManager key="dt-manager" adaptiveUrl={Config.ngirkartUrl}>
          <DigiThemeLoader
            key="dt-loader"
            adaptiveUrl={Config.adaptiveUrl}
            themeUuid={Config.forms.kompostSkjema.uuid}
            handleField={handleField}
            onChange={(name, value, invalid, dirty) => {
              setFormState(d => {
                return { ...d, [name]: { value, invalid, dirty } };
              });
            }}
            formState={formState}
            lang={{
              dateFormat: "dd.MM.yyyy",
              dateFormatPlaceholder: "DD.MM.YYYY",
              months: [
                "Januar",
                "Februar",
                "Mars",
                "April",
                "Mai",
                "Juni",
                "Juli",
                "August",
                "September",
                "Oktober",
                "November",
                "Desember"
              ],
              weekdaysShort: ["Man", "Tir", "Ons", "Tor", "Fre", "Lør", "Søn"],
              weekdaysLong: [
                "Mandag",
                "Tirsdag",
                "Onsdag",
                "Torsdag",
                "Fredag",
                "Lørdag",
                "Søndag"
              ],
              clear: t("clear"),
              today: t("today")
            }}
          />
          <Attachments
            themeUuid={Config.forms.kompostSkjema.uuid}
            onAttachmentAdded={onAttachmentAdded}
            onAttachmentRemoved={onAttachmentRemoved}
            attachments={attachments}
            label={t("labelVedleggKompost")}
            baseUrl={Config.ngirkartUrl}
          />
          <Checkbox
            checked={confirmCorrectInformation}
            large={true}
            onChange={() =>
              setConfirmCorrectInformation(!confirmCorrectInformation)
            }
            label={t("confirmCorrectInformationKompost")}
          />
          <a className="link-block" href="https://www.ngir.no/hushald/avtaleheimekompostering" target="_blank" rel="noopener noreferrer">{t("kompostTerms")}</a>
          <button
            className={["btn", "btn-secondary", "dt-button"].join(" ")}
            type="button"
            onClick={() => history.push("/")}
          >
            {t("cancel")}
          </button>

          <button
            disabled={isRecordInvalid || saving || !confirmCorrectInformation || attachments.length === 0}
            className={["btn", "dt-button"].join(" ")}
            type="button"
            onClick={() => save()}
          >
            {t("save")}
          </button>
        </DigiThemeManager>
      )}
      {!saving && error && (
        <Error key="from-error" onClose={() => setError(false)} />
      )}
      {!saving && success && <Success idx={id} key="form-success" />}
      {saving && (
        <div key="form-loader" className="loader">
          <Loading />
        </div>
      )}
    </>
  );
};

export default translate("Skjema")(withRouter(KompostSkjema));
